<template>
  <v-row class="fill-height pb-12 pt-2 mx-1">
    <v-col>
      <!-- Header del calendario -->
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">Hoy</v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Día</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Semana</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Mes</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet class="calendar" height="600">
        <!-- Calendario -->
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="#ffff"
          :events="events"
          :event-color="getEventColor"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
          :event-text-color="text"
        ></v-calendar><!---->
        <!-- Detalle del evento -->
        <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
          <v-card flat>
            <v-toolbar style="margin-bottom:-10px;" class="elevation-0">
              <v-icon :color="selectedEvent.color" class="pr-2"> mdi-checkbox-blank</v-icon>
              <v-toolbar-title color="#3c4043" @click="open(selectedEvent.contact)">{{selectedEvent.type}}</v-toolbar-title><!-- #32241c -->
              <v-spacer></v-spacer>
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                <v-btn v-show="permissions('editActivities')" icon v-bind="attrs" v-on="on">
                  <v-icon color="#3c4043" small>mdi-dots-vertical</v-icon>
                </v-btn>
                </template>
                <v-list style="font-size:13px;">
                  <v-list-item @click="editItem(selectedEvent)">
                    <v-icon small class="mr-2">
                      mdi-pencil
                    </v-icon>
                    Editar
                  </v-list-item>
                  <v-list-item @click="deleteItem(selectedEvent)">
                    <v-icon small class="mr-2">
                      mdi-delete
                    </v-icon>
                    Eliminar
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn icon @click="selectedOpen=false">
                <v-icon color="#3c4043" small> mdi-close</v-icon><!-- #32241c -->
              </v-btn>
            </v-toolbar>
            <div class="pt-2 mx-4 mb-0" color="primary">
              <v-icon color="primary" class="mr-2 mb-1">mdi-calendar</v-icon><span style="font-size:15px; color: #1976d1; font-weight: 500;">{{fecha(selectedEvent.start)}}</span>
            </div>
            <div class="pb-1 mx-4 mb-2" color="primary">
              <v-icon color="primary" class="mr-2 mb-1">mdi-clock-outline</v-icon><span style="font-size:15px; color: #1976d1; font-weight: 500;">{{formatAMPM(selectedEvent.start)}}</span>
            </div>
            <v-card-text class="px-6 pt-1" style="color:#3c4043;">
              <v-list-item :to="{ path: '/clients/client/'+ selectedEvent.company}" class="pa-0"><v-icon small class="mr-2 mb-1">mdi-domain</v-icon><strong>Empresa: </strong>{{selectedEvent.name}}</v-list-item>
              <div class="py-1"></div>
              <v-icon small class="mr-2 mb-1">mdi-account</v-icon><strong>Contacto: </strong>{{selectedEvent.contact}}
              <v-divider class="my-4"></v-divider>
              <strong>Detalle: </strong>{{selectedEvent.description}}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn elevation="0" style="text-transform:capitalize; letter-spacing:0;" class="px-5 mb-2 mr-2" color="primary">Terminar</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
    <!-- Crear actividad -->
    <v-dialog v-model="createDialog" max-width="700px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn bottom color="#e74919" dark fab fixed right v-bind="attrs" v-on="on">
            <v-icon color="white">  mdi-plus </v-icon>
        </v-btn> 
      </template>
      <create @closeCreateDialogCalendar="closeCreateDialogCalendar"/>
    </v-dialog>
    <!-- Editar actividad -->
    <v-dialog v-model="editDialog" max-width="700px">
      <edit v-bind:calendar="calendar" @closeDialogEditCalendar="closeDialogEditCalendar"/>
    </v-dialog>
    <!-- Dialogo confirmación -->
    <div class="text-center">
      <v-bottom-sheet  v-model="sheet" inset>
        <v-sheet class="text-center" height="150px">
          <div class="pt-6">
          ¿Seguro que deseas borrar esta actividad?
          </div>
          <v-btn class="mt-4" text color="error" @click="deleteCalendar()">
          Eliminar
          </v-btn>
          <v-btn class="mt-4" text color="grey" @click="cancel()">
          Cancelar
          </v-btn>
        </v-sheet>
      </v-bottom-sheet>
    </div>
  </v-row>
</template>
<script>
  import axios from "axios";
  import Filter from "../activities/filter"
  import Create from "../activities/create"
  import Edit from "../activities/edit"
  export default {
    components: {
      'filterClients':Filter,
      'create':Create,
      'edit':Edit,
    }, 
    data: () => ({
      sheet: false,
      calendar:'',
      editDialog: false,
      createDialog: false,


      detalleCliente:false,
      ventas:'',
      cliente:'',



      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Mes',
        week: 'Semana',
        day: 'Día',
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
    }),
    mounted () {
      this.$refs.calendar.checkChange()
      this.$store.dispatch('calendar/getCalendars')
    },
    created(){
      this.$store.dispatch('currentUser/getUser')
      this.$store.dispatch('company/getCompanies')
      this.$store.dispatch('contact/getContacts')
      this.$store.dispatch('calendar/getCalendars')
      this.$store.dispatch('activity/getActivities')
      this.$store.dispatch('user/getUsers') 
    },
    computed:{
      currentUser:{
        get(){
          return this.$store.state.currentUser.user;
        }
      },
      updateRange () {
        const cal = []
        var info = this.$store.state.calendar.calendars//.filter(id => new Date(id.created_at) >= min && new Date(id.created_at) >= max)
        for (let i = 0; i < info.length; i++) {
          cal.push({
            company: info[i].company_id,
            name: this.company(info[i].company_id),
            start: new Date(info[i].date),
            end: new Date(info[i].date),
            color: this.color(info[i]),//#fbd3c2
            type: this.activity(info[i].activity_id),
            timed: true,
            description:info[i].description,
            contact:this.contact(info[i].contact_id),
            id:info[i].id,
            completed:info[i].completed,
            text:this.textColor(info[i])
          })
        }
        this.events = cal
        return this.events 
      },
    },
    methods: {
      permissions(permission){
        if(this.currentUser.permissions!=undefined){
          if(this.currentUser.permissions.includes(permission)){
            return true
          }else{
            return false
          }
        }else if(this.currentUser.id==1){
          return true
        }else{
          return false
        }
        
      },
      formatAMPM(date) {
        var hours = new Date(date).getHours();
        var minutes = new Date(date).getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
      },
      fecha(date){
        var meses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre");
        var diasSemana = new Array("Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado");
        return diasSemana[new Date(date).getDay()] + ", " + new Date(date).getDate() + " de " + meses[new Date(date).getMonth()] + " de " + new Date(date).getFullYear()
      },
      color(calendar){
        var color = this.$store.state.activity.activities.filter(activity=>activity.id == calendar.activity_id).map(activity => activity.color)[0]
        if(calendar.completed==1){
          return '#f7f7f7'
        }else{
          return color
        }
      },
      text(calendar){
        return calendar.text
      },
      textColor(calendar){
        var color = this.$store.state.activity.activities.filter(activity=>activity.id == calendar.activity_id).map(activity => activity.color)[0]
        if(calendar.completed==1){
          return color
        }else{
          return 'white'
        }
      },
      activity(id){
        return this.$store.state.activity.activities.filter(activity=>activity.id == id).map(activity => activity.type)[0]
      },
      contact(id){
        return this.$store.state.contact.contacts.filter(contact=>contact.id == id).map(contact => contact.name + ' ' + contact.last)[0]
      },
      company(id){
        return this.$store.state.company.companies.filter(company=>company.id == id).map(company => company.name)[0]
      },
      open(id){
        this.cliente=id 
        this.detalleCliente=true
      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          setTimeout(() => {
            this.selectedOpen = true
          }, 10)
        }
        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }
        nativeEvent.stopPropagation()
      },
      status(id){
        var editedItem = this.$store.state.calendar.calendars.filter(calendar=>calendar.id == id)[0]
        editedItem.completed = 'realizado'
        axios.put("https://" + localStorage.getItem("tenant") + ".universidaddeventas.uno/calendar/update",Object.assign(editedItem)).then(response=>{
          this.updateRange
        })
      },
      deleteCalendar(){
        axios.delete("https://" + localStorage.getItem("tenant") + ".universidaddeventas.uno/calendar/delete/"+this.deleteId).then(response => {
          this.deleteId = ''
          this.sheet = false
          this.$store.dispatch('calendar/getCalendars')
        });
      },
      cancel(){
        this.deleteId = ''
        this.sheet = false
      },
      deleteItem (item) {
        this.deleteId = item.id
        this.sheet = true
      },
      editItem(item){
        this.calendar = this.$store.state.calendar.calendars.filter(calendar=>calendar.id == item.id)[0]
        this.editDialog = true
      },
      closeDialogEditCalendar: function(params) {
        this.editDialog = false;
        this.$store.dispatch('calendar/getCalendars')
      },
      closeCreateDialogCalendar: function(params) {
        this.createDialog = false;
        this.$store.dispatch('calendar/getCalendars')
      },
    },
  }
</script>
<style>
  .v-menu__content {
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 13.33%), 0px 8px 10px 1px rgb(0 0 0 / 9.33%), 0px 3px 14px 2px rgb(0 0 0 / 8%);
  }
</style>